<template>
    <section v-if="!haveRole(['ROLE_FRONT_PROVEEDORES_PAC_INSTITUCIONES_VIEW'])">
        <v-row>
            <v-col cols="12" class="bg-dark rounded-lg">
                <v-img contain class="mt-8 mb-10 mx-auto" src="@/assets/img/comprasal_oficial.png" max-width="350" />
            </v-col>
        </v-row>
        <!-- v-cards de estadísticas, notificaciones y acciones rápidas -->
        <v-row>
            <v-col cols="12" sm="6" md="8">
                <v-card class="rounded-lg">
                    <v-card-title class="bg-dark rounded-top-lg">
                        <h3 class="text-center white--text">Reporte de PAC</h3>
                    </v-card-title>
                    <v-card-text>
                        <v-autocomplete 
                        v-model="pacFilters.year" 
                        :items="anios" 
                        label="Año" 
                        outlined 
                        dense
                        item-value="anio" 
                        item-text="anio" 
                        hide-details 
                        clearable 
                        class="mt-4">
                        </v-autocomplete>

                        <v-autocomplete 
                        v-model="pacFilters.institucion" 
                        :items="instituciones" 
                        item-text="nombre"
                        item-value="id" 
                        label="Institución" 
                        outlined 
                        dense 
                        hide-details 
                        return-object clearable
                        class="mt-4">
                        </v-autocomplete>

                            <!-- <v-checkbox
                            v-model="pacFilters.publico"
                            label="Reporte público"
                            class="my-4"
                            color="primary"
                            hide-details
                            ></v-checkbox> -->

                        <v-btn color="primary" class="mt-4 mb-2" @click="downloadPac()">
                            Generar reporte
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </section>
</template>

<style lang="scss" scoped>
.bg-dark {
    background: #343a40;
}

.color-dark {
    color: #343a40;
}
</style>

<script>

export default {
    name: "pacInstituciones",
    data: () => ({
        anios: [],
        pacFilters: {
            year: new Date().getFullYear(),
            institucion: null,
        },
        instituciones: [],
    }),
    methods: {
        async getInstituciones() {
            const response =
                await this.services.Proveedores.getCatalogoInstituciones({
                    pagination: false,
                });
            this.instituciones = response.data;
        },
        async downloadPac() {
            if (!this.pacFilters.institucion || !this.pacFilters.year) {
                this.temporalAlert({
                    show: true,
                    message: "Por favor seleccione los campos de Institución y un Año",
                    type: "warning",
                });
                return;
            }
            const response = await this.services.Proveedores.descargarReportePac(
                this.pacFilters.institucion?.id,
                {
                    anio_calendario: this.pacFilters.year,
                }
            );

            if (!response) {
                this.temporalAlert({
                    show: true,
                    message: "Ocurrió un error al descargar el documento",
                    type: "error",
                });
                return;
            }

            const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            const file = new File([blob], this.pacFilters.institucion?.nombre, {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(file);
            link.download = `ReportePAC-${this.pacFilters?.year}.xlsx`;
            link.click();
        },
    },
    computed: {
        async years() {
            const { data } = await this.services.Auth.obtenerAniosFiscales();

            // const currentYear = new Date().getFullYear();
            // const years = [];
            // for (let i = currentYear; i <= currentYear + 5; i++) {
            //   years.push(i);
            // }
            return data;
        },
        activarBotonDescargarPac() {
            this.pacFilters.institucion?.id || this.pacFilters.year;
        },
    },
    async created() {
        const { data } = await this.services.Auth.obtenerAniosFiscales();
        this.anios = data;
        await this.getInstituciones();
    },
};
</script>
